<template>
  <div>
    <vx-card title="Top Sold Project" :is-refresh="isLoading">

      <vs-select class="w-full vs-select-small" v-model="params.period" @input="onChangePeriod($event)">
        <vs-select-item class="vs-select-small" v-for="(it, index) in months" :key="index" :value="it.value" :text="it.name"/>
      </vs-select>

      <div class="overflow-y-auto mt-4" style="height: 418pt">
        <template v-if="data.queryResult.length > 0">
          <div v-for="(item, index) in data.queryResult" :key="index" :class="{'mt-4': index}">
            <div class="flex justify-between">
              <div class="flex flex-col">
                  <span class="mb-1">{{ item.nama_proyek }}</span>
                  <h4 class="text-primary">{{ item.total }} Unit Sold</h4>
              </div>
            </div>
            <vs-divider/>
          </div>
        </template>

        <!--empty state-->
        <div v-if="data.queryResult.length < 1 && !isLoading" class="w-full h-full flex flex-col items-center justify-center">
          <img alt="" class="w-32 opacity-75" src="@/assets/svg/no-data.svg"/>
          <p class="text-sm mt-3">No data.</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import moment from 'moment/moment'

export default {
  name: 'TopSoldProject',
  components: {},
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        period: null,
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD')
      },
      data: {
        queryResult: []
      },
      months: [
        { value: null, name: 'This Year' },
        { value: 1, name: 'Januari' },
        { value: 2, name: 'Pebruari' },
        { value: 3, name: 'Maret' },
        { value: 4, name: 'April' },
        { value: 5, name: 'Mei' },
        { value: 6, name: 'Juni' },
        { value: 7, name: 'Juli' },
        { value: 8, name: 'Agustus' },
        { value: 9, name: 'September' },
        { value: 10, name: 'Oktober' },
        { value: 11, name: 'Nopember' },
        { value: 12, name: 'Desember' }
      ]
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    onChangePeriod (monthInt) {
      let startDate = null
      let endDate = null
      if (monthInt === null) {
        startDate = moment().startOf('year')
        endDate = moment().endOf('year')
      } else {
        const currentYear = moment().format('YYYY')
        startDate = moment(`${currentYear}-${monthInt}-01`, 'YYYY-M-DD')
        endDate = startDate.clone().endOf('month')
      }

      this.params.start_date = startDate.format('YYYY-MM-DD')
      this.params.end_date = endDate.format('YYYY-MM-DD')

      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 65
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
